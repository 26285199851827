@import "../global.scss";

.containerStyles{
  margin: 0 auto;
  object-fit: cover;
}
.imgContainer{
  width: 100%;
  height: 430px;
  min-height: 420px;
}

.topHead{
  position:absolute;
  margin-top: 0px;
  padding-top: 0px;
  z-index: 2;
  object-fit: cover;
}
.topText{
  margin: 4% 3% 3% 3%;
  h6{
   color: rgb(230, 105, 3);
    font-weight: 800;
    font-size: 18px;
    justify-content: space-between;
    margin-bottom: 2%;
  }

  h1{
    font-weight: 1000;
    color: rgb(230, 105, 3);
    font-size: 42px;
    justify-content: space-between;
  }
  .textLine{
    margin-top:2%;
    font-weight: 800;
    display:inline-block;
    font-size: 18px;
    color: rgb(230, 105, 3);
    width:45%;
    text-align: justify;
    word-wrap:break-word;
  }
}


.secFact{
  display: block;
   margin: 50px 0px 40px;
   
  .secFactsRow{
    width: 100%;
    margin: 0px;
  }

  .secFacts{
    color: rgb(230, 105, 3);
    text-align: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10%;
    text-decoration: none;
  }
}

.serviceBox{

  margin: 10px;
  padding: 20px;

  .tsIntro{
    text-align: center;
   

    .infoSubTitle{
      font-weight: 800;
      color: rgb(230, 105, 3);
      font-size: 2.7rem;
    }
  }
 p{
  text-align: center;
 }
  .subTitle{
    color: orange;
  }
}

.tsFactsTitle{
  font-weight: 800;
  color: rgb(230, 105, 3);
  font-size: 1.2rem;
}

.infoText {
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.0rem;
  color: rgb(230, 105, 3);
}

.serviceImg{
  width: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.globeImage{
   align-items: center;
   text-align: center;
}

.quoteItem{
  -webkit-box-shadow: 10px 12px 20px 6px rgba(0, 0, 0, 0.47);
  box-shadow: 10px 12px 20px 6px rgba(105, 105, 105, 0.47);
  padding: 10px;
}
.quoteTitle{
  text-align: center;
  color: rgb(230, 105, 3);
  font-weight: 800;
  font-size: 2.7rem;
}
.quoteText{
  margin: 0 0 10px;
  display:inline-block;
  padding: 0 40px 30px 50px;
  background-color: #fff;
  position: relative;
  font-size: 18px;
  font-style: italic;
  color: orange;
  .quoteFooter{
    color: rgb(230, 105, 3);
  }
}

.slick-prev:before, .slick-next:before {
  color: black !important;
}

.homeVisaItemBox{
  -webkit-box-shadow: 10px 12px 20px 6px rgba(0, 0, 0, 0.47);
  box-shadow: 10px 12px 20px 6px rgba(105, 105, 105, 0.47);
}

.visaItem{
  text-align: center;
  align-items: center;
  justify-content:center;
  margin: 10px;
  cursor: pointer;
  padding: 0px;
  font-weight: bold;
  color: rgb(138, 131, 131);
}

.visaItem:hover{
  background: rgb(163, 163, 212);
  color: white;
}
.visaItem img{
  width: 100%;
  object-fit: cover;
}  

.blogItemBox{
  -webkit-box-shadow: 10px 12px 20px 6px rgba(0, 0, 0, 0.47);
  box-shadow: 10px 12px 20px 6px rgba(105, 105, 105, 0.47);
}

.blogBox{
  text-align: center;
  align-items: center;
  justify-content:center;
  cursor: pointer;
  padding: 10px;
  font-weight: bold;
  .blogTitle{
     color: rgb(230, 105, 3);
    font-weight: bold;
  }

  .blogLink{
    color: rgb(230, 105, 3);
    font-weight: bold;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.blogButton{
    background-color: rgb(230, 105, 3);
    color: white;
}

.blogLink:hover{
    background-color: rgb(84, 212, 212);
    border-radius: 4px;
    color: rgb(230, 105, 3);
    font-weight: bold;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: all 2s ease-out;
}

}

.blogBox img{
  width: 100%;
  object-fit: cover;
} 

.homeCallUs{
  justify-content: center;
  text-align: center;
  align-items: center;
  color: lightblue;
  font-weight: 800;
  font-size:3.2rem;
}

@media screen and (max-width: 960px){

.topText{
  h6{
    font-weight: 600;
    font-size: 19px;
  }

  h1{
    font-weight: 800;
    font-size: 38px;
  }
  .textLine{
    font-weight: 600;
    font-size: 19px;
    width: 90%;
  }
}
}

@media screen and (max-width: 400px){
  .topText{
    h6{
      font-weight: 400;
      font-size: 14px;
    }
  
    h1{
      font-weight: 600;
      font-size: 32px;
    }
    .textLine{
      font-weight: 400;
      font-size: 14px;
      width: 98%;
    }
  }
}