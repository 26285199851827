@import "../global.scss";

.privacySecBox{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201,201, 201, 0.47);
  }

  .secTitle{
    text-align: left;
    color: rgb(230, 105, 3);
    font-weight: 800;
    text-align: center;
  }

  .boxText{
    text-align: justify;
    color: rgb(230, 105, 3);
  }
 
  .bankTable{
    width: 98%;
    margin: 10px;
   
  }

  .tableHead{
    background-color: rgb(230, 105, 3);
    color: white;
    th{
      padding: 10px;
    }
  }

  .tableBody{
    td{
      padding: 10px;
    }
  }