
.socialBox{
  display:flex;
  gap: 10%;
  text-align: center;
  justify-content: center;
  list-style: none;
}

.socialText{
  text-decoration: none;
  color: rgb(230, 105, 3);
}

 .infoSocialIcon{
  width: 30px;
   cursor: pointer;
 }

 .infoSocialIcon:hover{
  width: 40px;
  transition: all 2s ease-out;
}
/*
@media screen and (max-width: 960px){
  .infoSocialIcon{
    width: 2.0vw;
    margin-top: 5px;
    cursor: pointer;
  }

  .infoSocialIcon:hover{
   width: 4.2vw;
   transition: all 2s ease-out;
 }
}

@media screen and (max-width: 400px){
.infoSocialIcon{
  width: 5.0vw;
  cursor: pointer;
}

.infoSocialIcon:hover{
 width: 6.2vw;
 transition: all 2s ease-out;
}
} */