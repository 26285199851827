@import url("https://fonts.googleapis.com/css2?family=Exo:wght@300;400;500&display=swap");

:root {
  --background-color: #7494a8;
  --primary-color: #a8074a;
  --hr-color: #c4c4c4;
  --font-family: "Exo", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}

html {
  scroll-behavior: smooth;
}

body {
  color: rgb(104, 195, 231);
  background-color: var(--background-color);
  font-family: var(--font-family);
}

.body-inner{
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0.2rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;

  background-color: var(--primary-color);
}

.infoContainer{
  margin-top: 60px;
  padding: auto;
}