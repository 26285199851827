@import "../global.scss";

.mainContainerStyles{
  margin: 0 auto;
  object-fit: cover;
}
.contactImgContainer{
  width: 100%;
  height: 420px;
  min-height: 320px;
}

.mr-0{
  padding: 10px;
  margin: 0px;
  background-color: rgb(236, 214, 10);
 border-radius: 50%;
}
.contactHomeText{
  width: 100%;
  text-align: center;
  align-items: center;
  position:absolute;
  margin-top: 0px;
  color: rgb(230, 105, 3);
  font-size: 20px;
  font-weight: 800;
  z-index: 1;
  h1{
    margin-top: 150px;
    font-weight: 1000;
    color: rgb(230, 105, 3);
    font-size: 3.2rem;
  }
  .homeTextLink{
    color: rgb(230, 105, 3);
    font-size: 20px;
    font-weight: 800;
    text-decoration: none;
  }
}

label{
  color: rgb(230, 105, 3);
}


.contactSecBox{
  margin: 0 0 0 0;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
  box-shadow: 2px 4px 10px 1px rgba(201,201, 201, 0.47);
  padding: 10px;
  align-items: center;
  text-align: center;
}

.contactTitleBox{
  align-items: center;
  text-align: center;
}
.contactTitle{
  text-align: center;
  color: rgb(230, 105, 3);
  font-weight: 800;
  font-size: 2.7rem;
}

.tsContact{
  color: rgb(230, 105, 3);
  padding: 5px;
  h6{
    font-size: 18px;
    margin-left: 5px;
    font-weight: 800;
  }
  span{
    padding: 5px;
  }
}

.boxText{
  text-align: justify;
  color: rgb(230, 105, 3);
  font-size: 18px;
}

.contextForm{
  -webkit-box-shadow: 4px 8px 20px 2px rgba(0,0,0,0.47);
  box-shadow: 4px 8px 20px 2px rgba(20, 12, 12, 0.47);
  padding: 10px;
}