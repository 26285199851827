@import "../global.scss";

.mainContainerStyles{
  margin: 0 auto;
  object-fit: cover;
}
.aboutImgContainer{
  width: 100%;
  height: 420px;
  min-height: 320px;
}

.aboutHomeText{
  width: 100%;
  text-align: center;
  align-items: center;
  position:absolute;
  margin-top: 0px;
  color: rgb(230, 105, 3);
  font-size: 20px;
  font-weight: 800;
  
  z-index: 1;
  h1{
    margin-top: 150px;
    font-weight: 1000;
    color: rgb(230, 105, 3);
    font-size: 3.2rem;
  }
  .homeTextLink{
    color: rgb(230, 105, 3);
    font-size: 20px;
    font-weight: 800;
    text-decoration: none;
  }
}



.aboutSecBox{
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
  box-shadow: 2px 4px 10px 1px rgba(201,201, 201, 0.47);
  padding-top: 0px;
}

.secTitle{
  color: rgb(230, 105, 3);
  text-align: left;
  font-weight: 800;
   font-size: 2.0rem;
}

.secMidTitle{
  text-align: center;
  color: rgb(230, 105, 3);
  font-weight: 800;
  font-size: 2.7rem;
  margin-bottom: 20px;
}

.tsServiceBox{
 padding: 10px;
}

.iconStyle{
  text-align: center;
  align-items: center;
}


.serviceMidleBox{
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
  box-shadow: 2px 4px 10px 1px rgba(201,201, 201, 0.47);
  background-color: #c7edf0;
  padding: 10px;
}

.boxImage{
  align-items: center;
  text-align: center;
  object-fit: cover;
  justify-content: center;
  overflow: hidden;
}
.boxImage img{
width: 600px;
height: 600px;
margin: -80px 0 0 -160px;
}

.boxTitle{
  padding: 10px;
  margin: auto;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  color: rgb(230, 105, 3);
  background-color: rgb(230, 227, 102);;
}

.readMorebtn{
  border: none;
  padding: 0px;
  top: 0;
  margin: 0px;
  background-color: none;
}

.aboutBoxText{
  align-items: flex-start;
  text-align: justify;
  color: rgb(230, 105, 3);
  font-size: 18px;
}

.aboutImg{
   padding-top: 1vw;
}

.aboutMidBoxText{
  background-color: rgb(230, 105, 3);
  gap: 10px;
  align-items: flex-start;
  text-align: justify;
  letter-spacing: normal;
  line-height: 38px;
  color: white;
  font-size: 18px;
}

.infoIcons{
  font-size: 125px;
  color: rgb(211, 150, 20);
  text-align: center;
  align-items: center;
  font-weight: 100px;
}
