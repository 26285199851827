.topper {
    width: 100%;
    top: 0;
    height: 5vh;
    display: flex;
    align-items: center;
    font-size: 0.5rem;

    .infoBox {
        display: grid;
        grid-template-columns: repeat(6, auto);
        grid-gap: 10px;
        text-align: center;
        justify-content: end;
        padding-top: 15px;
        width: 120vw;
        list-style: none;

        .infoText {
            text-decoration: none;
            padding: 0.5rem 1rem;
            font-size: 1.0rem;
            color: rgb(230, 105, 3);
        }

        .infoText:hover {
            background-color: rgb(230, 227, 102);
            border-radius: 4px;
            text-decoration: none;
            padding: 0.5rem 1rem;
            transition: all 2s ease-out;
        }

        .infoIcon {
            color: rgb(230, 105, 3);
        }

        .infoWaIcon {
            width: 2vw;
        }
    }
}

.navBar {
    background-color: white;
    height: 10.5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 3;

    .navLogo {
        padding: 0px 10px 0px 10px;
        margin-left: 50px;
        cursor: pointer;
        text-decoration: none;
        font-size: 2rem;

        .logoImg {
            max-width: 150px;
        }
    }

    .menuIcon {
        display: none;
    }

    .navMenu {
        display: grid;
        grid-template-columns: repeat(8, auto);
        grid-gap: 10px;
        text-align: center;
        justify-content: end;
        list-style: none;
        margin-top: 20px;

        .navLink {
            color: rgb(230, 105, 3);
            font-weight: bold;
            text-decoration: none;
            padding: 0.5rem 1rem;
        }

        .navLink:hover {
            background-color: rgb(230, 227, 102);
            border-radius: 4px;
            color: rgb(230, 105, 3);
            font-weight: bold;
            text-decoration: none;
            padding: 0.5rem 1rem;
            transition: all 2s ease-out;
        }

        .applyNow {
            margin: 20px 20px;
            background-color: rgb(230, 105, 3);
            color: rgb(230, 227, 102);
            border-radius: 5px;
        }
    }

}


@media screen and (max-width: 1000px) {

    .navBar {
        height: 10.0vh;

        .navLogo {
            margin-top: -50px;
            padding: 0px;
            left: 0px;
            transform: translate(25%, 50%);
            margin-left: -25px;

            .logoImg {
                max-width: 145px;
                min-width: 145px;
            }
        }

        .menuIcon {
            display: block;
            position: absolute;
            top: -15px;
            right: 10px;
            font-size: 1.8rem;
            color: rgb(133, 204, 52);
            transform: translate(-100%, 60%);
            cursor: pointer;
        }

        .navMenu {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 90vh;
            position: absolute;
            top: 6vh;
            left: -95%;
            transition: all .4s ease;
            overflow: auto;
            opacity: 0;

            .navLink {
                align-items: center;
                text-align: center;
                padding: 1.5rem;
                margin-top: 0.1rem;
                width: 100%;
                display: table;
            }

            .navLink:hover {
                background-color: rgb(230, 227, 102);
                border-radius: 0px;
                width: 90%;
                color: rgb(230, 105, 3);
                font-weight: bold;
            }

            .applyNow {
                width: 100%;
                margin-left: -20px;
            }
        }

        .navMenu.active {
            background-color: rgb(230, 227, 102);
            opacity: 1;
            left: 0;
            transition: all .5s ease;
            z-index: 3;
        }
    }

    .topper {
        .infoBox {
            #lbl {
                display: none;
            }

            .infoIcon {
                font-size: 20px;
            }

            .infoWaIcon {
                width: 3.5vw;
            }
        }
    }

}

@media screen and (max-width: 460px) {
    .navBar {
        height: 10.0vh;

        .logoImg {
            max-width: 120px;
            min-width: 120px;
        }

        .menuIcon {
            font-size: 1.5rem;
            transform: translate(-1%, 25%);
        }

        .applyNow {
            width: 100%;
            margin-left: -20px;
        }
    }

    .topper {
        .infoBox {
            .infoIcon {
                font-size: 20px;
            }

            .infoWaIcon {
                width: 3.5vw;
            }
        }
    }
}