.footer{
    width: 100%;
    background-color: rgb(241, 243, 240);
    bottom: 0;
    z-index: 1;
}

.globeXImage{
    align-items: center;
    text-align: center;
 }

 .globeImg{
    width: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

.fLogoTitle{
    color: rgb(230, 105, 3);
    font-size: 20px;
    font-weight: 800;
}
.fLogoLast{
    color: rgb(230, 105, 3);
    font-size: 20px;
    font-weight: 800;
}
.fLogoSubtitle{
    color: rgb(175, 142, 34);
    font-size: 18px;
    font-weight: 700;
}
.fSubText{
    color: rgb(230, 105, 3);
    font-size: 16px;
    text-decoration: none;
}

.fSubTextLink{
    color: rgb(230, 105, 3);
    font-size: 16px;
    text-decoration: none;
}

.footerText{
    bottom: 0;
}
.myIcon{
    max-width: 25px;
}