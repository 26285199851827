@import "../global.scss";

.mainContainerStyles{
  margin: 0 auto;
  object-fit: cover;
}
.serviceImgContainer{
  width: 100%;
  height: 420px;
  min-height: 320px;
}
.pageTitle{
  text-align: center;
}

.serviceHomeText{
  width: 100%;
  text-align: center;
  align-items: center;
  position:absolute;
  margin-top: 0px;
  color: rgb(230, 105, 3);
  font-size: 20px;
  font-weight: 800;
  z-index: 1;
  h1{
    margin-top: 150px;
    font-weight: 1000;
    color: rgb(230, 105, 3);
    font-size: 3.2rem;
  }

  .homeTextLink{
    color: rgb(230, 105, 3);
    font-size: 20px;
    font-weight: 800;
    text-decoration: none;
  }
}

.portfolio {
  position: relative;
  .title__container{
    text-align: center;
    h2{
      font-weight: 800;
      color: rgb(230, 105, 3);
      font-size: 2.7rem;
    }
    p{
      color: rgb(230, 105, 3);
    }
  }
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: white;
    gap: 2rem;
    .portfolio__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      background-color: #181818;
      padding: 2rem;
      text-align: center;

      .image {
        display: block;
        width: 250px;
        height: 150px;
        overflow: hidden;

        position: relative;
        border-radius: 1px;
        margin: 0 auto;
        img {
          left: 0;
          top: 0%;
          width: 100%;
          height: auto;
          position: absolute;
          z-index: 0;
          margin: 0;
          padding: 0;
          -webkit-transition: bottom 3s;
          -moz-transition: bottom 3s;
          -ms-transition: bottom 3s;
          -o-transition: bottom 3s;
          transition: top 3s;
          &:hover {
            top: -300%;
            -webkit-transition: all 3s;
            -moz-transition: all 3s;
            -ms-transition: all 3s;
            -o-transition: all 3s;
            transition: all 3s;
          }
        }
      }
      &:nth-of-type(2) {
        .image {
          img {
            &:hover {
              top: -345%;
            }
          }
        }
      }
      &:nth-of-type(3) {
        .image {
          img {
            &:hover {
              top: -390%;
            }
          }
        }
      }
    }
  }
}

.blogs{
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
  box-shadow: 2px 4px 10px 1px rgba(201,201, 201, 0.47);
  display: flex;
  flex-wrap:wrap;
  padding: 15px;
  text-align: center;
  align-items: center;
}

.callUs{
  justify-content: center;
  text-align: center;
  align-items: center;
  color: lightblue;
  font-weight: 800;
  font-size:3.2rem;
}

.blogInfo{
  margin: 4rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .pageTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
  }
  .blogs {
   -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201,201, 201, 0.47);
    display: flex;
    flex-wrap:wrap;
    padding: 15px;
    gap: 5rem;
    text-align: center;
    align-items: center;
    .blog {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.3);
      border-bottom: 0.5rem transparent solid;
      transition: 0.3s ease-in-out;
      &:hover {
        border-color: var(--primary-color);
      }
      img {
        width: 99%;
      }
      .data {
        padding: 1rem;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        p {
          font-size: 0.9rem;
          font-weight: 100;
        }
      }
    }
  }
}



@media screen and (min-width: 280px) and (max-width: 1080px) {
  .portfolio .container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .portfolio .container .portfolio__container {
    padding: 1rem;
  }
  .portfolio .container .portfolio__container .image {
    width: 200px;
  }
  .portfolio .container .portfolio__container .image img:hover {
    top: -240% !important;
  }
  .portfolio .container .portfolio__container:nth-of-type(2) .image img:hover {
    top: -260%;
  }
  
  .title {
    h2 {
      font-size: 2.4rem;
      color: var(--primary-color);
    }
  }
  .blogs {
    flex-direction: column;
    gap: 2rem;
  }
}